<template>
  <!-- 基本信息 -->
  <div class="baseinfo mb-4">
    <div class="row mb-4">
      <div class="col-4">
        <div>
          <label class="form-label">{{
            $t("fabric.detail.top_water_contact_angle")
          }}</label>
          <div class="input-group">
            <input
              type="text"
              class="form-control"
              v-model="waterInfo.top_water_contact_angle"
            />
            <span class="input-group-text">deg</span>
          </div>
        </div>
      </div>
      <div class="col-4">
        <div>
          <label class="form-label">{{
            $t("fabric.detail.bottom_water_contact_angle")
          }}</label>
          <div class="input-group">
            <input
              type="text"
              class="form-control"
              v-model="waterInfo.bottom_water_contact_anglee"
            />
            <span class="input-group-text">deg</span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- 2D版型 -->
  <!-- <div class="mt-5">
    <label for="description-field" class="form-label fs-18">{{
      $t("fabric.detail.liquid_water_conductivity")
    }}</label>
    <div class="text-muted">{{ $t("base.upload_excel_limit") }}</div>
    <DropZone
      accept="application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      @drop.prevent="drop"
      @change.prevent="selectedFile"
      style="min-height: 220px"
      ipt-id="mmtFileInput"
      limit="50M"
    />
    <div class="text-center text-muted" v-if="!mmt_preview.length">
      {{ $t("base.no_data") }}
    </div>
    <ul class="list-unstyled mb-0" id="dropzone-preview">
      <div
        class="border rounded"
        v-for="(file, index) of mmt_preview"
        :key="index"
      >
        <div class="d-flex p-2">
          <div class="flex-grow-1">
            <div class="pt-1">
              <h5 class="fs-14 mb-1">
                <a :href="file.url" :download="file.url" class="text-break">{{
                  file.filename
                }}</a>
              </h5>
            </div>
          </div>
          <div class="flex-shrink-0 ms-3">
            <button
              type="button"
              class="btn btn-sm btn-danger"
              @click="deleteFile(index)"
            >
              {{ $t("base.delete") }}
            </button>
          </div>
        </div>
      </div>
    </ul>
  </div> -->
  <div class="row">
    <UploadFileView
      v-model:selectedList="mmt_preview"
      iptId="mmtFileInput"
      accept="application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      :title="$t('fabric.detail.liquid_water_conductivity')"
      :hint="$t('base.upload_excel_limit')"
    ></UploadFileView>
  </div>
</template>

<script>
import _ from "lodash";
import UploadFileView from "@/views/components/UploadFileView-ftt.vue";
import { getFileValuesFromDetail } from "@/utils/fetch-data";
import { uploadFile } from "@/service";
export default {
  emits: ["update:addForm"],
  props: {
    addForm: {
      type: Object,
      default: () => {},
    },
    detailForm: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      waterInfo: {
        top_water_contact_angle: "",
        bottom_water_contact_anglee: "",
        mmt: [],
      },
      mmt_preview: [],
      inited: false
    };
  },
  watch: {
    mmt_preview: {
      immediate: true,
      deep: true,
      handler(val) {
        if (val) {
          const list = _.map(this.mmt_preview, "id");
          this.waterInfo.mmt = list;
        }
      },
    },
    detailForm: {
      handler(val) {
        if (val && !this.inited) {
          this.inited = true
          const detailFormData = _.cloneDeep(val);
          this.waterInfo.top_water_contact_angle = _.get(
            detailFormData,
            "top_water_contact_angle",
            ""
          );
          this.waterInfo.bottom_water_contact_anglee = _.get(
            detailFormData,
            "bottom_water_contact_anglee",
            ""
          );
          this.waterInfo = _.merge(this.waterInfo, detailFormData);
          if (this.waterInfo.ftt && this.waterInfo.ftt.length) {
            const ftt_data = getFileValuesFromDetail(detailFormData, "ftt");
            this.waterInfo.ftt = ftt_data.ids;
          }
          if (this.waterInfo.pictures && this.waterInfo.pictures.length) {
            const pic_data = getFileValuesFromDetail(
              detailFormData,
              "pictures"
            );
            this.waterInfo.pictures = pic_data.ids;
          }
          const mmt = _.get(detailFormData, "mmt", []);
          if (mmt && mmt.length > 0) {
            const mmt_data = getFileValuesFromDetail(detailFormData, "mmt");
            console.log("mmt_data", mmt_data);
            this.waterInfo.mmt = mmt_data.ids;
            this.mmt_preview = mmt_data.previews;
          }
        }
      },
      deep: true,
      immediate: true,
    },
    waterInfo: {
      handler(val) {
        let re_form = _.cloneDeep(val);
        this.$emit("update:addForm", re_form);
      },
      deep: true,
    },
  },
  components: {
    UploadFileView,
  },
  methods: {
    selectedFile() {
      let dropzoneFile = document.querySelector("#mmtFileInput").files[0];
      if (!dropzoneFile) return;
      uploadFile(dropzoneFile)
        .then((res) => {
          this.mmt_preview.push(res.data.payload);
          this.waterInfo.mmt.push(res.data.payload.id);
        })
        .catch((e) => {
          console.log(e);
        });
    },
    drop(e) {
      let dropzoneFile = e.dataTransfer.files[0];
      console.log(dropzoneFile);
      if (!dropzoneFile) return;
      // this.uploadFileSign(dropzoneFile);
    },
    deleteFile(i) {
      this.waterInfo.mmt = _.filter(
        this.waterInfo.mmt,
        (o) => o != this.mmt_preview[i].id
      );
      this.mmt_preview.splice(i, 1);
    },
  },
};
</script>

<style scoped lang="scss"></style>
