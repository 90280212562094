<template>
  <!-- 基本信息 -->
  <!-- <div class="baseinfo">
    <div
      :class="['row text-gray', index != 0 && 'mt-4']"
      v-for="(base, index) in baseInfo"
      :key="index"
    >
      <div class="col-3">{{ base.title }}</div>
      <div class="col">{{ base.value }}</div>
    </div>
  </div> -->
  <!-- 纱线 -->
  <div class="fabric">
    <label>{{ $t("yarn.name") }}</label>
    <div class="card-header" style="padding: 0.2rem 1rem"></div>
    <div>
      <table class="table table-borderless">
        <thead class="table-light">
          <tr>
            <th
              style="width: 25%"
              v-for="(title, index) in yarnTitle"
              :key="index"
            >
              {{ title }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(list, index) in yarnList" :key="index">
            <td class="table-index">#{{ index + 1 }}</td>
            <td class="table-id">
              <a
                class="text-decoration-underline cursor"
                @click="jump2Yarn(list.id)"
                >{{ list.yarn_id }}</a
              >
            </td>
            <td class="table-persentage">{{ list.persentage }}</td>
            <td class="table-type">{{ list.type }}</td>
          </tr>
        </tbody>
      </table>
      <div
        v-if="yarnList == undefined || yarnList.length == 0"
        style="position: relative; height: 40px"
      >
        <Empty flag="2" :content="$t('base.none')"></Empty>
      </div>
    </div>
  </div>
  <!-- 纤维 -->
  <div class="fiber mt-5">
    <label>{{ $t("fiber.name") }}</label>
    <div class="card-header" style="padding: 0.2rem 1rem"></div>
    <div>
      <table class="table table-borderless">
        <thead class="table-light">
          <tr>
            <th
              style="width: 25%"
              v-for="(title, index) in fiberTitle"
              :key="index"
            >
              {{ title }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(list, index) in fiberList" :key="index">
            <td class="table-index">#{{ index + 1 }}</td>
            <td class="table-index">
              <a
                class="text-decoration-underline cursor"
                @click="jump2Fiber(list.id)"
                >{{ list.fibre_id }}</a
              >
            </td>
            <td class="table-persentage">{{ list.persentage }}</td>
            <td class="table-type">{{ list.type }}</td>
          </tr>
        </tbody>
      </table>
      <div
        v-if="fiberList == undefined || fiberList.length == 0"
        style="position: relative; height: 40px"
      >
        <Empty flag="2" :content="$t('base.none')"></Empty>
      </div>
    </div>
  </div>
  <!-- <SankeyChartShow :sankey="sankey"></SankeyChartShow> -->
  <!-- <PreviewImg
    v-model:showPreviewImg="showPreviewImg"
    :src="src"
    v-if="showPreviewImg"
  ></PreviewImg> -->
</template>

<script>
// import PreviewImg from "@/components/common/PreviewImg";
import defaultImage from "@/assets/image.png";
import { getSankeyChartInfo } from "@/service";
import _ from "lodash";
export default {
  data() {
    return {
      defaultImage,
      baseInfo: [
        {
          title: this.$t("attribute.structure"),
          key: "structure",
          value: "",
        },
        {
          title: this.$t("fabric.thickness"),
          key: "fabric_thickness",
          value: "",
        },
        {
          title: this.$t("fabric.weight"),
          key: "fabric_weight",
          value: "",
        },
        {
          title: this.$t("attribute.water_contact_angle"),
          key: "water_contact_angle",
          value: "",
        },
        {
          title: this.$t("fabric.density"),
          key: "density",
          value: "",
        },
        {
          title: this.$t("fabric.porosity"),
          key: "porosity",
          value: "",
        },
        {
          title: this.$t("fabric.tortuous"),
          key: "tortuous_gas",
          value: "",
        },
      ],
      yarnTitle: [
        this.$t("attribute.number"),
        this.$t("yarn.id"),
        this.$t("yarn.percentage") +
          " (" +
          this.$t("yarn.percentage_unit") +
          ")",
        this.$t("yarn.type"),
      ],
      yarnList: [],
      fiberTitle: [
        this.$t("attribute.number"),
        this.$t("fiber.id"),
        this.$t("fiber.percentage") +
          " (" +
          this.$t("attribute.percentage") +
          ")",
        this.$t("fiber.type"),
      ],
      fiberList: [],
      ftts: [],
      pictures: [],
      id: "",
      fabricChartId: `fabric_chart${+new Date()}`,
      showPreviewImg: false,
      src: "",
      sankey: {},
    };
  },
  props: {
    detailForm: {
      type: Object,
      default: () => {},
    },
  },
  watch: {
    detailForm: {
      handler(val) {
        if (val) {
          this.baseInfo.forEach((info) => {
            for (let v in val) {
              if (info.key == v) {
                if (info.key == "code") {
                  const code = val[v];
                  const code_2 = val["code_2"];
                  const code_ciq = val["code_ciq"];
                  let value = "";
                  if (code) {
                    value += code + "-";
                  }
                  if (code_2) {
                    value += code_2 + "-";
                  } else {
                    value += "-";
                  }
                  if (code_ciq) {
                    value += code_ciq;
                  }
                  info.value = value;
                } else {
                  info.value = val[v];
                }
              }
            }
          });
          this.yarnList = _.get(val, "yarns", []);
          this.fiberList = _.get(val, "fibres", []);
          this.ftts = _.get(val, "ftt", []);
          this.pictures = _.get(val, "pictures", []);
          this.id = _.get(val, "id", "");
          // this.requestSankeyChart();
        }
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {
    showSankeyChart() {
      if (this.showType == "view" && this.sankey) {
        const nodes = this.sankey.nodes;
        if (nodes && nodes.length > 1) {
          return true;
        }
        return false;
      }
      return false;
    },
  },
  mounted() {},
  methods: {
    requestSankeyChart() {
      const params = {
        id: this.detailForm.id,
        key: this.detailForm.key,
      };
      getSankeyChartInfo(params)
        .then((res) => {
          if (res && res.payload) {
            this.sankey = res.payload;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    downloadFile(url) {
      window.open(url, "_self");
    },
    jump2Fiber(id) {
      if (id) {
        this.$router.push(`/fiber_detail/?id=${id}&type=view`);
      }
    },
    jump2Yarn(id) {
      if (id) {
        this.$router.push(`/yarn_detail/?id=${id}&type=view`);
      }
    },
    handleImgPreview(item) {
      this.src = item.url;
      if (this.src == null || this.src == undefined || this.src == "") {
        return;
      }
      this.showPreviewImg = true;
    },
    toReport() {
      const params = {
        type: "ftt",
        id: this.id,
      };
      const routeUrl = this.$router.resolve({
        name: "report",
        params: params,
      });
      window.open(routeUrl.href, "_blank");
    },
  },
  components: {
    // PreviewImg,
  },
};
</script>

<style scoped lang="less"></style>
<style>
.table-index {
  width: 25%;
}

.table-id {
  width: 25%;
}

.table-persentage {
  width: 25%;
}

.table-type {
  width: 25%;
}
</style>
