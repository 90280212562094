<template>
  <div class="heat_feel">
    <div class="d-flex py-4">
      <div class="heat_img">
        <img
          src="@/assets/img/skin_comfort.png"
          style="width: 100%; height: 400px; object-fit: contain"
          alt=""
        />
      </div>
      <div
        class="heat_info d-flex flex-column justify-content-between py-3 ms-5"
      >
        <div>
          <label class="form-label">{{ $t("radar_chart.prickle") }}</label>
          <input type="text" class="form-control" disabled />
        </div>
        <div>
          <label class="form-label">{{ $t("radar_chart.softness") }}</label>
          <input type="text" class="form-control" disabled />
        </div>
        <div>
          <label class="form-label">{{ $t("radar_chart.warmth") }}</label>
          <input type="text" class="form-control" disabled />
        </div>
        <div>
          <label class="form-label">{{ $t("radar_chart.total") }}</label>
          <input type="text" class="form-control" disabled />
        </div>
      </div>
      <div class="heat_chart ms-5">
        <div :id="chartId" style="height: 400px; width: 400px"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { radarChart } from "@/utils/chart.js";
export default {
  data() {
    return {
      chartId: `heat${+new Date()}`,
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.initChart();
    });
  },
  methods: {
    initChart() {
      this.heat_feel_chart();
    },
    heat_feel_chart() {
      let options = {
        data: [0, 0, 0, 0],
        ave: [0, 0, 0, 0],
        color: "#ED5E5E",
        name: this.$t("radar_chart.skin"),
        chartDom: document.querySelector(`#${this.chartId}`),
      };
      radarChart(options);
    },
  },
  components: {},
};
</script>

<style scoped lang="scss">
.heat_img {
  img {
    width: 20rem;
    height: 22rem;
  }
}
</style>
