<template>
    <!-- 基本信息 -->
    <div class="baseinfo">
        <div :class="['row text-gray', index != 0 && 'mt-4']" v-for="(base, index) in baseInfo" :key="index">
            <div class="col-3">{{ base.title }}</div>
            <div class="col">{{ base.value ? base.value : "-" }}</div>
        </div>
    </div>
    <!-- 水蒸汽透过性 -->
    <div class="water mt-5">
        <label>{{$t('attribute.water_vapor_permeability')}}</label>
        <div class="card-header" style="padding: 0.2rem 1rem"></div>
        <div>
            <table class="table table-borderless">
                <thead class="table-light">
                    <tr>
                        <th style="width: 33%;" v-for="(title, index) in waterTitle" :key="index">
                            {{ title }}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(list, index) in waterList" :key="index">
                        <td>#{{ index + 1 }}</td>
                        <td>{{ list.temperature }}</td>
                        <td>{{ list.wvp }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <!-- 热阻 -->
    <div class="heat mt-5">
        <label>{{$t('attribute.thermal_resistance')}}</label>
        <div class="card-header" style="padding: 0.2rem 1rem"></div>
        <div>
            <table class="table table-borderless">
                <thead class="table-light">
                    <tr>
                        <th style="width: 33%;" v-for="(title, index) in heatTitle" :key="index">
                            {{ title }}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(list, index) in heatList" :key="index">
                        <td>#{{ index + 1 }}</td>
                        <td>{{ list.temperature }}</td>
                        <td>{{ list.tr }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
    import _ from 'lodash'
    export default {
        data() {
            return {
                baseInfo: [{
                        title: this.$t('attribute.membrane_thickness'),
                        key: "thickness",
                        value: "",
                    },
                    {
                        title: this.$t('attribute.membrane_type'),
                        key: "membrane_type",
                        value: "",
                    },
                    {
                        title: this.$t('attribute.membrane_location'),
                        key: "membrane_location",
                        value: "",
                    },
                ],
                waterTitle: [this.$t('attribute.number'),
                    this.$t('attribute.temperature'),
                    this.$t('attribute.water_vapor_permeability'),
                ],
                heatTitle: [this.$t('attribute.number'),
                    this.$t('attribute.temperature'),
                    this.$t('attribute.thermal_resistance'),
                ],
                waterList: [],
                heatList: [],
            };
        },
        props: {
            detailForm: {
                type: Object,
                default: () => {},
            },
        },
        watch: {
            detailForm: {
                handler(val) {
                    if (val) {
                        this.baseInfo.forEach((info) => {
                            for (let v in val) {
                                if (info.key == v) {
                                    info.value = val[v];
                                }
                            }
                        });
                        this.waterList = _.get(val, 'water_vapour_permeability', []);
                        this.heatList = _.get(val, 'thermal_resistance', [])
                    }
                },
                deep: true,
                immediate: true,
            },
        },
        mounted() {},
        methods: {},
        components: {},
    };
</script>

<style scoped lang="less"></style>
