<template>
    <!-- 基本信息 -->
    <div class="baseinfo">
        <!-- <label>{{$t('attribute.base')}}</label>
        <div class="card-header" style="padding: 0.2rem 1rem"></div> -->
        <div class="row mt-4 text-gray" v-for="(base, index) in baseInfo" :key="index">
            <div class="col-3">{{ base.title }}</div>
            <div class="col">{{ base.value }}</div>
        </div>
    </div>
    <!-- 织物液态水分传导性能 -->
    <div class="mt-5">
        <label>{{$t('fabric.detail.liquid_water_conductivity')}}</label>
        <a v-if="!mmt || mmt.length == 0" href="javascript:return false;" class="ms-4">{{$t('base.to_view')}}</a>
        <a v-else href="javascript:;" class="ms-4" @click="toReport()">{{$t('base.to_view')}}</a>
        <div class="card-header" style="padding: 0.2rem 1rem"></div>
        <div class="border border-dashed" style="margin-top: 20px;">
            <ul class="d-flex">
                <li v-for="(item, key) in mmt" :key="key" @click="downloadFile(item.url)">
                    <img src="@/assets/file.png" alt="" style="width: 50px; height: 54px" />
                    <div>{{ item.name }}</div>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
    import _ from 'lodash'
    export default {
        data() {
            return {
                baseInfo: [{
                        title: this.$t('fabric.detail.sst'),
                        key: "sst",
                        value: "",
                    },
                    {
                        title: this.$t('fabric.detail.ssb'),
                        key: "ssb",
                        value: "",
                    },
                    {
                        title: this.$t('fabric.detail.r'),
                        key: "r",
                        value: "",
                    },
                    {
                        title: this.$t('fabric.detail.ommc'),
                        key: "ommc",
                        value: "",
                    },
                ],
                mmt: [],
                id: '',
            };
        },
        props: {
            detailForm: {
                type: Object,
                default: () => {},
            },
        },
        watch: {
            detailForm: {
                handler(val) {
                    if (val) {
                        this.baseInfo.forEach((info) => {
                            for (let v in val) {
                                if (info.key == v) {
                                    info.value = val[v];
                                }
                            }
                        });
                        this.mmt = _.get(val, 'mmt', [])
                        this.id = _.get(val, 'id', '');
                    }
                },
                deep: true,
                immediate: true,
            },
        },
        mounted() {},
        methods: {
            downloadFile(url) {
                window.open(url, '_blank')
            },
			toReport() {
				const params = {
					type: 'mmt',
					id: this.id
				};
				const routeUrl = this.$router.resolve({
					name: 'report',
					params: params
				});
				window.open(routeUrl.href, '_blank');
			}
        },
        components: {},
    };
</script>

<style scoped lang="less"></style>
