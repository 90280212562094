<template>
  <div v-if="showType != 'view'">
    <div class="row">
      <div class="col-4">
        <div>
          <label class="form-label">{{ $t("attribute.structure") }}</label>
          <input
            type="text"
            class="form-control"
            v-model="baseInfo.structure"
          />
        </div>
      </div>
      <div class="col-4">
        <div>
          <label class="form-label">{{
            $t("attribute.water_contact_angle")
          }}</label>
          <div class="input-group">
            <input
              type="text"
              class="form-control"
              v-model="baseInfo.water_contact_angle"
            />
            <span class="input-group-text">{{
              $t("attribute.water_contact_angle_unit")
            }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-4">
      <div class="col-4">
        <div>
          <label class="form-label">{{ $t("fabric.thickness") }}</label>
          <div class="input-group">
            <input
              type="text"
              class="form-control"
              v-model="baseInfo.fabric_thickness"
            />
            <span class="input-group-text">{{
              $t("fabric.thickness_unit")
            }}</span>
          </div>
        </div>
      </div>
      <div class="col-4">
        <div>
          <label class="form-label">{{ $t("fabric.weight") }}</label>
          <div class="input-group">
            <input
              type="text"
              class="form-control"
              v-model="baseInfo.fabric_weight"
            />
            <span class="input-group-text">{{ $t("fabric.weight_unit") }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <UploadFileView
        v-model:selectedList="ftt_preview"
        iptId="fttFileInput"
        accept="application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        :title="$t('fabric.detail.ftt')"
        :hint="$t('base.upload_excel_limit')"
        :tips="tips"
      ></UploadFileView>
    </div>
  </div>
  <div v-else>
    <div
      :class="['row text-gray', index != 0 && 'mt-4']"
      v-for="(base, index) in viewBaseInfo"
      :key="index"
    >
      <div class="col-3">{{ base.title }}</div>
      <div class="col">{{ base.value }}</div>
    </div>
    <!-- FTT -->
    <div class="mt-5">
      <label>
        <span>{{ $t("fabric.detail.ftt") }}</span>
        <i
          id="showTips"
          v-if="tips"
          class="ri-question-line"
          @mouseover="mouseOver()"
          @mouseleave="mouseLeave()"
        ></i>
      </label>
      <b-popover target="showTips" v-model:show="showTips" triggers="">
        <div>{{ tips }}</div>
      </b-popover>
      <a
        v-if="!ftts || ftts.length == 0"
        href="javascript:return false;"
        class="ms-4"
        >{{ $t("base.to_view") }}</a
      >
      <a v-else href="javascript:;" class="ms-4" @click="toReport()">{{
        $t("base.to_view")
      }}</a>
      <div class="border border-dashed">
        <ul class="d-flex">
          <li
            v-for="(item, key) in ftts"
            :key="key"
            @click="downloadFile(item.url)"
            style="margin-right: 10px"
          >
            <img
              src="@/assets/file.png"
              alt=""
              style="width: 50px; height: 54px"
            />
            <div>{{ item.name }}</div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import UploadFileView from "@/views/components/UploadFileView-ftt.vue";
import { getFileValuesFromDetail } from "@/utils/fetch-data";
import _ from "lodash";
export default {
  data() {
    return {
      tips: this.$t("fabric.tips"),
      showTips: false,
      baseInfo: {
        structure: "",
        fabric_thickness: "",
        fabric_weight: "",
        water_contact_angle: "",
        ftt: [],
      },
      ftt_preview: [],
      viewBaseInfo: [
        {
          title: this.$t("attribute.structure"),
          key: "structure",
          value: "",
        },
        {
          title: this.$t("attribute.water_contact_angle"),
          key: "water_contact_angle",
          value: "",
        },
        {
          title: this.$t("fabric.thickness"),
          key: "fabric_thickness",
          value: "",
        },
        {
          title: this.$t("fabric.weight"),
          key: "fabric_weight",
          value: "",
        },
      ],
      ftts: [],
      id: "",
      inited: false,
    };
  },
  props: {
    showType: {
      type: String,
      default: "",
    },
    detailForm: {
      type: Object,
      default: () => {},
    },
    searchList: {
      type: Object,
      default: () => {},
    },
    submitForm: {
      type: Object,
      default: () => {},
    },
  },
  emits: ["update:submitForm"],
  watch: {
    ftt_preview: {
      immediate: true,
      deep: true,
      handler(val) {
        if (val) {
          const list = _.map(this.ftt_preview, "id");
          this.baseInfo.ftt = list;
        }
      },
    },
    detailForm: {
      handler(val) {
        if (!this.inited) {
          this.inited = true
          const detailFormData = _.cloneDeep(val);
          this.viewBaseInfo.forEach((info) => {
            for (let v in detailFormData) {
              if (info.key == v) {
                info.value = val[v];
              }
            }
          });
          this.baseInfo = _.merge(this.baseInfo, detailFormData);
          if (this.baseInfo.ftt && this.baseInfo.ftt.length) {
            const ftt_data = getFileValuesFromDetail(detailFormData, "ftt");
            this.baseInfo.ftt = ftt_data.ids;
            this.ftt_preview = ftt_data.previews;
          }
          if (this.baseInfo.pictures && this.baseInfo.pictures.length) {
            const pic_data = getFileValuesFromDetail(detailFormData, "pictures");
            this.baseInfo.pictures = pic_data.ids;
          }
          if (this.baseInfo.mmt && this.baseInfo.mmt.length) {
            const mmt_data = getFileValuesFromDetail(detailFormData, "mmt");
            this.baseInfo.mmt = mmt_data.ids;
          }

          this.ftts = _.get(val, "ftt", []);
          this.id = _.get(val, "id", "");
        }
      },
      deep: true,
    },
    baseInfo: {
      handler(val) {
        let re_form = _.cloneDeep(val);
        console.log('ftt有变化，变化后的form值：', re_form)
        this.$emit("update:submitForm", re_form);
      },
      deep: true,
    },
  },
  methods: {
    mouseOver() {
      this.showTips = true;
    },
    mouseLeave() {
      this.showTips = false;
    },
    toReport() {
      const params = {
        type: "ftt",
        id: this.id,
      };
      const routeUrl = this.$router.resolve({
        name: "report",
        params: params,
      });
      window.open(routeUrl.href, "_blank");
    },
    downloadFile(url) {
      window.open(url, "_blank");
    },
  },
  components: {
    UploadFileView,
  },
};
</script>

<style scoped lang="less"></style>
