<template>
    <!-- 基本信息 -->
    <div class="baseinfo">
        <div :class="['row text-gray', index != 0 && 'mt-4']" v-for="(base, index) in baseInfo" :key="index">
            <div class="col-3">{{ base.title }}</div>
            <div class="col">{{ base.value }}</div>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                baseInfo: [{
                        title: this.$t('attribute.heating_load'),
                        key: "heating_load",
                        value: "",
                    },
                    {
                        title: this.$t('attribute.switch_on_temperature'),
                        key: "switch_on_temperature",
                        value: "",
                    },
                    {
                        title: this.$t('attribute.switch_off_temperature'),
                        key: "switch_off_temperature",
                        value: "",
                    },
                ],
            };
        },
        props: {
            detailForm: {
                type: Object,
                default: () => {},
            },
        },
        watch: {
            detailForm: {
                handler(val) {
                    if (val) {
                        this.baseInfo.forEach((info) => {
                            for (let v in val) {
                                if (info.key == v) {
                                    info.value = val[v];
                                }
                            }
                        });
                    }
                },
                deep: true,
                immediate: true,
            },
        },
        mounted() {},
        methods: {},
        components: {},
    };
</script>

<style scoped lang="less"></style>
