<template>
    <div class="heat_feel">
        <!-- 1行 -->
        <div class="row mb-4 justify-content-between">
            <div class="col-3">
                <label class="form-label">{{$t('attribute.phase_change_material_add_on_level')}}</label>
                <div class="input-group">
                    <input type="text" class="form-control" v-model="changeInfo.phase_change_material_add_on_level" />
                    <span class="input-group-text">{{$t('attribute.percentage')}}</span>
                </div>
            </div>
            <div class="col-3">
                <label class="form-label">{{$t('attribute.radius')}}</label>
                <div class="input-group">
                    <input type="text" class="form-control" v-model="changeInfo.radius" />
                    <span class="input-group-text">{{$t('attribute.cm')}}</span>
                </div>
            </div>
            <div class="col-3">
                <label class="form-label">{{$t('attribute.density')}}</label>
                <div class="input-group">
                    <input type="text" class="form-control" v-model="changeInfo.phase_change_material_density" />
                    <span class="input-group-text">{{$t('attribute.density_unit')}}</span>
                </div>
            </div>
            <div class="col-3">
                <label class="form-label">{{$t('attribute.latent_heat_of_fusion')}}</label>
                <div class="input-group">
                    <input type="text" class="form-control" v-model="changeInfo.latent_heat_of_fusion" />
                    <span class="input-group-text">{{$t('attribute.latent_heat_of_fusion_unit')}}</span>
                </div>
            </div>
        </div>
        <!-- 2行 -->
        <div class="row mb-4 justify-content-between">
            <div class="col-3">
                <label class="form-label">{{$t('attribute.melting_point')}}</label>
                <div class="input-group">
                    <input type="text" class="form-control" v-model="changeInfo.melting_point" />
                    <span class="input-group-text">{{$t('attribute.deg')}}</span>
                </div>
            </div>
            <div class="col-3">
                <label class="form-label">{{$t('attribute.thermal_conductivity_of_liquid_pcm')}}</label>
                <div class="input-group">
                    <input type="text" class="form-control" v-model="changeInfo.thermal_conductivity_of_liquid_pcm" />
                    <span class="input-group-text">{{$t('attribute.thermal_conductivity_unit')}}</span>
                </div>
            </div>
            <div class="col-3">
                <label class="form-label">{{$t('attribute.thermal_conductivity_of_siquid_pcm')}}</label>
                <div class="input-group">
                    <input type="text" class="form-control" v-model="changeInfo.thermal_conductivity_of_siquid_pcm" />
                    <span class="input-group-text">{{$t('attribute.thermal_conductivity_unit')}}</span>
                </div>
            </div>
            <div class="col-3">
                <label class="form-label">{{$t('attribute.heat_trnasfer_coefficient')}}</label>
                <div class="input-group">
                    <input type="text" class="form-control" v-model="changeInfo.heat_trnasfer_coefficient" />
                    <span class="input-group-text">{{$t('attribute.thermal_conductivity_unit')}}</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import _ from "lodash";
    export default {
        data() {
            return {
                changeInfo: {
                    phase_change_material_add_on_level: "",
                    radius: "",
                    phase_change_material_density: "",
                    latent_heat_of_fusion: "",
                    melting_point: "",
                    thermal_conductivity_of_liquid_pcm: "",
                    thermal_conductivity_of_siquid_pcm: "",
                    heat_trnasfer_coefficient: "",
                },
              inited: false,
            };
        },
        props: {
            addForm: {
                type: Object,
                default: () => {},
            },
            detailForm: {
                type: Object,
                default: () => {},
            },
        },
        emits: ["update:addForm"],
        watch: {
            detailForm: {
                handler(val) {
                    if (val && !this.inited) {
                        this.inited = true
                        const detailFormData = _.cloneDeep(val)
                        this.changeInfo.phase_change_material_add_on_level = _.get(detailFormData,
                            'phase_change_material_add_on_level', '')
                        this.changeInfo.radius = _.get(detailFormData, 'radius', '')
                        this.changeInfo.phase_change_material_density = _.get(detailFormData,
                            'phase_change_material_density', '')
                        this.changeInfo.latent_heat_of_fusion = _.get(detailFormData, 'latent_heat_of_fusion', '')
                        this.changeInfo.melting_point = _.get(detailFormData, 'melting_point', '')
                        this.changeInfo.thermal_conductivity_of_liquid_pcm = _.get(detailFormData,
                            'thermal_conductivity_of_liquid_pcm', '')
                        this.changeInfo.thermal_conductivity_of_siquid_pcm = _.get(detailFormData,
                            'thermal_conductivity_of_siquid_pcm', '')
                        this.changeInfo.heat_trnasfer_coefficient = _.get(detailFormData, 'heat_trnasfer_coefficient',
                            '')
                    }
                },
                deep: true,
                immediate: true,
            },
            changeInfo: {
                handler(val) {
                    let re_form = _.cloneDeep(val);
                    this.$emit("update:addForm", re_form);
                },
                deep: true,
            },
        },
        mounted() {},
        methods: {},
        components: {},
    };
</script>

<style scoped lang="scss">
    .heat_img {
        img {
            width: 20rem;
            height: 22rem;
        }
    }
</style>
