<template>
    <!-- 基本信息 -->
    <div class="baseinfo">
        <div :class="['row text-gray', index != 0 && 'mt-4']" v-for="(base, index) in baseInfo" :key="index">
            <div class="col-3">{{ base.title }}</div>
            <div class="col">{{ base.value }}</div>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                baseInfo: [{
                        title: this.$t('attribute.phase_change_material_add_on_level'),
                        key: "phase_change_material_add_on_level",
                        value: "",
                    },
                    {
                        title: this.$t('attribute.radius'),
                        key: "radius",
                        value: "",
                    },
                    {
                        title: this.$t('attribute.density'),
                        key: "phase_change_material_density",
                        value: "",
                    },
                    {
                        title: this.$t('attribute.latent_heat_of_fusion'),
                        key: "latent_heat_of_fusion",
                        value: "",
                    },
                    {
                        title: this.$t('attribute.melting_point'),
                        key: "melting_point",
                        value: "",
                    },
                    {
                        title: this.$t('attribute.thermal_conductivity_of_liquid_pcm'),
                        key: "thermal_conductivity_of_liquid_pcm",
                        value: "",
                    },
                    {
                        title: this.$t('attribute.thermal_conductivity_of_siquid_pcm'),
                        key: "thermal_conductivity_of_siquid_pcm",
                        value: "",
                    },
                    {
                        title: this.$t('attribute.heat_trnasfer_coefficient'),
                        key: "heat_trnasfer_coefficient",
                        value: "",
                    },
                ],
            };
        },
        props: {
            detailForm: {
                type: Object,
                default: () => {},
            },
        },
        watch: {
            detailForm: {
                handler(val) {
                    if (val) {
                        this.baseInfo.forEach((info) => {
                            for (let v in val) {
                                if (info.key == v) {
                                    info.value = val[v];
                                }
                            }
                        });
                    }
                },
                deep: true,
                immediate: true,
            },
        },
        mounted() {},
        methods: {},
        components: {},
    };
</script>

<style scoped lang="less"></style>
