<template>
  <div class="hand_feel">
    <div class="d-flex py-4">
      <div class="hand_img">
        <img
          src="@/assets/img/hand_feel.png"
          style="width: 100%; height: 400px; object-fit: contain"
          alt=""
        />
      </div>
      <div class="hand_info d-flex flex-column justify-content-between ms-5">
        <div>
          <label class="form-label">{{ $t("radar_chart.roughness") }}</label>
          <input type="text" class="form-control" disabled />
        </div>
        <div>
          <label class="form-label">{{ $t("radar_chart.softness") }}</label>
          <input type="text" class="form-control" disabled />
        </div>
        <div>
          <label class="form-label">{{ $t("radar_chart.warmth") }}</label>
          <input type="text" class="form-control" disabled />
        </div>
        <div>
          <label class="form-label">{{ $t("radar_chart.total") }}</label>
          <input type="text" class="form-control" disabled />
        </div>
      </div>
      <div class="hand_chart ms-5">
        <div :id="handChartId" style="height: 400px; width: 400px"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { radarChart } from "@/utils/chart.js";
export default {
  data() {
    return {
      handChartId: `hand${+new Date()}`,
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.initChart();
    });
  },
  methods: {
    initChart() {
      this.hand_feel_chart();
    },
    hand_feel_chart() {
      let options = {
        data: [0, 0, 0, 0],
        ave: [0, 0, 0, 0],
        color: "#50C3E6",
        name: this.$t("radar_chart.feel"),
        chartDom: document.querySelector(`#${this.handChartId}`),
      };
      radarChart(options);
    },
  },
  components: {},
};
</script>

<style scoped lang="scss">
.hand_img,
.skin_img {
  img {
    width: 20rem;
    height: 22rem;
  }
}
</style>
