<template>
  <!-- 基本信息 -->
  <div class="baseinfo">
    <div class="row mb-4">
      <div class="col-3">
        <div>
          <label class="form-label">{{ $t("attribute.name") }}</label>
          <input type="text" class="form-control" v-model="baseInfo.name" />
        </div>
      </div>
      <div class="col-3">
        <div>
          <label class="form-label">{{ $t("attribute.type") }}</label>
          <input type="text" class="form-control" v-model="baseInfo.type" />
        </div>
      </div>
      <!-- <div class="col-3">
        <div>
          <label class="form-label">{{ $t("attribute.structure") }}</label>
          <input
            type="text"
            class="form-control"
            v-model="baseInfo.structure"
          />
        </div>
      </div>
      <div class="col-3">
        <div>
          <label class="form-label">{{ $t("fabric.thickness") }}</label>
          <div class="input-group">
            <input
              type="text"
              class="form-control"
              v-model="baseInfo.fabric_thickness"
            />
            <span class="input-group-text">{{
              $t("fabric.thickness_unit")
            }}</span>
          </div>
        </div>
      </div>
      <div class="col-3 py-3">
        <div>
          <label class="form-label">{{ $t("fabric.weight") }}</label>
          <div class="input-group">
            <input
              type="text"
              class="form-control"
              v-model="baseInfo.fabric_weight"
            />
            <span class="input-group-text">{{ $t("fabric.weight_unit") }}</span>
          </div>
        </div>
      </div>
      <div class="col-3 py-3">
        <div>
          <label class="form-label">{{
            $t("attribute.water_contact_angle")
          }}</label>
          <div class="input-group">
            <input
              type="text"
              class="form-control"
              v-model="baseInfo.water_contact_angle"
            />
            <span class="input-group-text">{{
              $t("attribute.water_contact_angle_unit")
            }}</span>
          </div>
        </div>
      </div> -->
    </div>
    <div class="row mb-4 justify-content-between">
      <div class="col-3">
        <div>
          <label class="form-label">{{ $t("attribute.code") }}</label>
          <Multiselect
            id="item"
            class="form-control"
            v-model="baseInfo.code_1"
            label="title"
            value-prop="code"
            :close-on-select="true"
            :searchable="true"
            :create-option="false"
            :options="code_options"
            @select="selectCode"
            @clear="clearCode"
          />
        </div>
      </div>
      <div class="col-3">
        <div
          style="position: relative; top: 100%; transform: translateY(-100%)"
        >
          <Multiselect
            id="item"
            class="form-control"
            v-model="hscode"
            label="title"
            value-prop="code"
            :close-on-select="true"
            :searchable="true"
            :create-option="false"
            :options="sub_list"
            @select="getCodeTitle"
            @clear="clearCodeTitle"
          />
        </div>
      </div>
      <div class="col-3">
        <div
          style="position: relative; top: 100%; transform: translateY(-100%)"
        >
          <input
            v-model="baseInfo.code_2"
            @input="baseInfo.code_2 = stringRegEx(0, baseInfo.code_2, 2)"
            type="text"
            class="form-control"
          />
        </div>
      </div>
      <div class="col-3">
        <div
          style="position: relative; top: 100%; transform: translateY(-100%)"
        >
          <input
            v-model="baseInfo.code_ciq"
            @input="baseInfo.code_ciq = stringRegEx(1, baseInfo.code_ciq, 3)"
            type="text"
            class="form-control"
          />
        </div>
      </div>
    </div>
  </div>

  <YarnAddTable v-model:selectedList="baseInfo.yarns"></YarnAddTable>
  <FibreAddTable v-model:selectedList="baseInfo.fibres"></FibreAddTable>
  <!-- <UploadFileView
    v-model:selectedList="ftt_preview"
    iptId="fttFileInput"
    accept="application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    :title="$t('fabric.detail.ftt')"
    :hint="$t('base.upload_excel_limit')"
  ></UploadFileView> -->
  <UploadFileView
    v-model:selectedList="pictures_preview"
    iptId="pictureFileInput"
    accept=".jpg,.png"
    :title="$t('attribute.picture')"
    :hint="$t('base.upload_picture_limit')"
  ></UploadFileView>
</template>

<script>
import YarnAddTable from "@/views/components/YarnAddTable.vue";
import FibreAddTable from "@/views/components/FibreAddTable.vue";
import UploadFileView from "@/views/components/UploadFileView.vue";
import { getHSCode } from "@/service/index";
import _ from "lodash";
import { getFileValuesFromDetail } from "@/utils/fetch-data";
import { stringRegEx } from "@/utils/common.js";
import { getHscodeDbIndex } from "@/service/index";
export default {
  components: {
    YarnAddTable,
    FibreAddTable,
    UploadFileView,
  },
  props: {
    addForm: {
      type: Object,
      default: () => {},
    },
    detailForm: {
      type: Object,
      default: () => {},
    },
    searchList: {
      type: Object,
      default: () => {},
    },
  },
  emits: ["update:addForm", 'update:hscode', 'update:hscodeTitle', 'update:associatedIndex'],
  data() {
    return {
      baseInfo: {
        code_1: "",
        code_2: "",
        code_ciq: "",
        name: "",
        type: "",
        structure: "",
        fabric_thickness: "",
        fabric_weight: "",
        water_contact_angle: "",
        code: "",
        yarns: [],
        fibres: [],
        ftt: [],
        pictures: [],
      },
      ftt_preview: [],
      pictures_preview: [],
      code_options: [],
      sub_list: [],
      hscode: null,
      hscodeTitle: null,
      associatedIndex:{}, // 关联的指标
      inited: false,
    };
  },
  methods: {
    stringRegEx,
    selectCode(init) {
      const self = this;
      const index = _.findIndex(this.code_options, function (o) {
        return o.code == self.baseInfo.code_1;
      });
      if (init != "init") {
        this.baseInfo.code = "";
      }
      if (index >= 0 && index < this.code_options.length) {
        const item = this.code_options[index];
        this.sub_list = item.sub_list;
        if (this.hscode && init == "init") {
          // 获取codeTitle
          const index = _.findIndex(this.sub_list, function (o) {
            return o.code == self.hscode;
          });
          if (index >= 0 && index < this.sub_list.length) {
            const item = this.sub_list[index];
            this.hscodeTitle = item.title;
            this.$emit('update:hscodeTitle', item.title)
          }
        }
      }
    },
    clearCode() {
      this.baseInfo.code = "";
      this.sub_list = [];
    },
    getCodeTitle(code) {
      console.log('code变化：', code)
      if (!code) {
        this.hscodeTitle = "";
        this.associatedIndex = {};
        return;
      }
      this.baseInfo.code = code;
      const index = _.findIndex(this.sub_list, function (o) {
        return o.code == code;
      });
      if (index >= 0 && index < this.sub_list.length) {
        const item = this.sub_list[index];
        this.hscodeTitle = item.title;
      }
      // 获取关联的指标
      const params = {
        code: code,
        key: 'fabric'
      };
      getHscodeDbIndex(params)
          .then((res) => {
            if (res && res.payload) {
              this.associatedIndex = res.payload
              this.$emit('update:associatedIndex', this.associatedIndex)
              this.$emit('update:hscodeTitle', this.hscodeTitle)
              this.$emit('update:hscode', code)
            }
          })
          .catch((err) => {
            console.log(err);
          });
    },
    clearCodeTitle() {
      this.hscodeTitle = "";
      this.associatedIndex = {};
    },
    requestHSCode(key) {
      if (this.code_options.length) {
        return
      }
      const params = {
        key: key,
      };
      getHSCode(params)
        .then((res) => {
          if (res && res.payload) {
            this.code_options = res.payload;
            this.selectCode("init");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  watch: {
    ftt_preview: {
      immediate: true,
      deep: true,
      handler(val) {
        if (val) {
          const list = _.map(this.ftt_preview, "id");
          this.baseInfo.ftt = list;
        }
      },
    },
    pictures_preview: {
      immediate: true,
      deep: true,
      handler(val) {
        if (val) {
          const list = _.map(this.pictures_preview, "id");
          this.baseInfo.pictures = list;
        }
      },
    },
    detailForm: {
      handler(val) {
        if (val && !this.inited) {
          this.inited = true;
          const detailFormData = _.cloneDeep(val);
          this.baseInfo.name = _.get(detailFormData, "name", "");
          this.baseInfo.type = _.get(detailFormData, "type", "");
          this.baseInfo.structure = _.get(detailFormData, "structure", "");
          this.baseInfo.fabric_thickness = _.get(
            detailFormData,
            "fabric_thickness",
            ""
          );
          this.baseInfo.fabric_weight = _.get(
            detailFormData,
            "fabric_weight",
            ""
          );
          this.baseInfo.water_contact_angle = _.get(
            detailFormData,
            "water_contact_angle",
            ""
          );

          if (detailFormData.code && !this.hscode) {
            this.baseInfo.code = _.get(detailFormData, "code", "");
            this.hscode = this.baseInfo.code;
            // console.log('hscode初始赋值：', this.hscode)

            // 获取关联的指标
            const params = {
              code: this.hscode,
              key: 'fabric'
            };
            getHscodeDbIndex(params)
                .then((res) => {
                  if (res && res.payload) {
                    this.associatedIndex = res.payload
                    this.$emit('update:associatedIndex', this.associatedIndex)
                  }
                })
                .catch((err) => {
                  console.log(err);
                });

            if (this.baseInfo.code && this.baseInfo.code.length == 6) {
              this.baseInfo.code_1 = this.baseInfo.code.slice(0, 4);
              this.selectCode("init");
            }
          }

          this.baseInfo.code_2 = _.get(detailFormData, "code_2", "");
          this.baseInfo.code_ciq = _.get(detailFormData, "code_ciq", "");

          this.baseInfo.yarns = _.get(detailFormData, "yarns", []);
          this.baseInfo.fibres = _.get(detailFormData, "fibres", []);
          // this.baseInfo.tortuous_gas = _.get(detailFormData, 'tortuous_gas', '');
          // this.baseInfo.porosity = _.get(detailFormData, 'porosity', '');
          // this.baseInfo.ftt = _.get(detailFormData, 'ftt', []);
          // this.baseInfo.pictures = _.get(detailFormData, 'pictures', []);

          if (!this.baseInfo.ftt.length) {
            const ftt_data = getFileValuesFromDetail(detailFormData, "ftt");
            this.baseInfo.ftt = ftt_data.ids;
            this.ftt_preview = ftt_data.previews;
          }
          if (!this.baseInfo.pictures.length) {
            const pic_data = getFileValuesFromDetail(
              detailFormData,
              "pictures"
            );
            this.baseInfo.pictures = pic_data.ids;
            this.pictures_preview = pic_data.previews;
          }

          this.requestHSCode(_.get(detailFormData, "key", "fabric"));
        } else {
          this.requestHSCode("fabric");
        }
      },
      deep: true,
      immediate: true,
    },
    baseInfo: {
      handler(val) {
        let re_form = _.cloneDeep(val);
        this.$emit("update:addForm", re_form);
      },
      deep: true,
    },
  },
};
</script>

<style scoped lang="scss"></style>
