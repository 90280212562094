<template>
  <div class="heat">
    <div class="row mb-4 justify-content-between">
      <div class="col-3">
        <label class="form-label">{{ $t("attribute.heating_load") }}</label>
        <div class="input-group">
          <input
            type="text"
            class="form-control"
            v-model="heatInfo.heating_load"
          />
          <span class="input-group-text">{{
            $t("attribute.heating_load_unit")
          }}</span>
        </div>
      </div>
      <div class="col-3">
        <label class="form-label">{{
          $t("attribute.switch_on_temperature")
        }}</label>
        <div class="input-group">
          <input
            type="text"
            class="form-control"
            v-model="heatInfo.switch_on_temperature"
          />
          <span class="input-group-text">{{ $t("attribute.deg") }}</span>
        </div>
      </div>
      <div class="col-3">
        <label class="form-label">{{
          $t("attribute.switch_off_temperature")
        }}</label>
        <div class="input-group">
          <input
            type="text"
            class="form-control"
            v-model="heatInfo.switch_off_temperature"
          />
          <span class="input-group-text">{{
            $t("attribute.switch_off_temperature_unit")
          }}</span>
        </div>
      </div>
    </div>
    <div class="hand_feel">
      <div class="d-flex align-items-center justify-content-between">
        <label class="fs-18">{{ $t("attribute.tensile_properties") }}</label>
        <b-button
          variant="success"
          class="bg-gradient waves-effect waves-light text-end"
          @click="addInfo('tensile')"
        >
          {{ $t("base.add1") }}
        </b-button>
      </div>
      <div class="card-header" style="padding: 0.2rem 1rem"></div>
      <div style="max-height: 400px; overflow: auto">
        <table class="table">
          <thead class="table-light">
            <tr>
              <th style="width: 25%" scope="col">
                {{ $t("attribute.number") }}
              </th>
              <th style="width: 25%" scope="col">
                {{ $t("attribute.tensile_stress") }}
              </th>
              <th style="width: 25%" scope="col">
                {{ $t("attribute.tensile_strain") }}
              </th>
              <th style="width: 25%">{{ $t("base.operation") }}</th>
            </tr>
          </thead>
          <tbody class="table-responsive">
            <tr
              v-for="(tensile, index) in heatInfo.tensile_properties"
              :key="index"
            >
              <td style="width: 25%">#{{ index + 1 }}</td>
              <td style="width: 25%">
                <input
                  type="text"
                  class="form-control"
                  v-model="tensile.tensile_stress"
                />
              </td>
              <td style="width: 25%">
                <input
                  type="text"
                  class="form-control"
                  v-model="tensile.tensile_strain"
                />
              </td>
              <td style="width: 25%">
                <div @click="deleteSteam('tensile', index)">
                  <i class="ri-indeterminate-circle-line fs-16 text-secondary">
                  </i>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div
      class="mt-5 border-top-dashed border-1 border-light"
      style="padding: 0.2rem 1rem"
    ></div>
    <div class="air_feel mt-5">
      <div class="d-flex align-items-center justify-content-between">
        <label class="fs-18">{{ $t("attribute.permeability") }}</label>
        <b-button
          variant="success"
          class="bg-gradient waves-effect waves-light text-end"
          @click="addInfo('air')"
        >
          {{ $t("base.add1") }}
        </b-button>
      </div>
      <div class="card-header" style="padding: 0.2rem 1rem"></div>
      <div style="max-height: 400px; overflow: auto">
        <table class="table">
          <thead class="table-light">
            <tr>
              <th style="width: 25%" scope="col">
                {{ $t("attribute.number") }}
              </th>
              <th style="width: 25%" scope="col">
                {{ $t("attribute.pressure_range") }}
              </th>
              <th style="width: 25%" scope="col">
                {{ $t("attribute.flow_rate") }}
              </th>
              <th style="width: 25%">{{ $t("base.operation") }}</th>
            </tr>
          </thead>
          <tbody class="table-responsive">
            <tr v-for="(air, index) in heatInfo.air_permeability" :key="index">
              <td style="width: 25%">#{{ index + 1 }}</td>
              <td style="width: 25%">
                <input
                  type="text"
                  class="form-control"
                  v-model="air.pressure_range"
                />
              </td>
              <td style="width: 25%">
                <input
                  type="text"
                  class="form-control"
                  v-model="air.flow_rate"
                />
              </td>
              <td style="width: 25%">
                <div @click="deleteSteam('air', index)">
                  <i class="ri-indeterminate-circle-line fs-16 text-secondary">
                  </i>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div
      class="mt-5 border-top-dashed border-1 border-light"
      style="padding: 0.2rem 1rem; border-color: #ccc"
    ></div>
  </div>
</template>

<script>
import _ from "lodash";

export default {
  data() {
    return {
      heatInfo: {
        heating_load: "",
        switch_on_temperature: "",
        switch_off_temperature: "",
        tensile_properties: [], // tensile_stress tensile_strain
        air_permeability: [], // pressure_range flow_rate
      },
      inited: false,
    };
  },
  props: {
    addForm: {
      type: Object,
      default: () => {},
    },
    detailForm: {
      type: Object,
      default: () => {},
    },
  },
  emits: ["update:addForm"],
  watch: {
    detailForm: {
      handler(val) {
        if (val && !this.inited) {
          this.inited = true;
          const detailFormData = _.cloneDeep(val);
          this.heatInfo.heating_load = _.get(
            detailFormData,
            "heating_load",
            ""
          );
          this.heatInfo.switch_on_temperature = _.get(
            detailFormData,
            "switch_on_temperature",
            ""
          );
          this.heatInfo.switch_off_temperature = _.get(
            detailFormData,
            "switch_off_temperature",
            ""
          );
          this.heatInfo.tensile_properties = _.get(
            detailFormData,
            "tensile_properties",
            []
          );
          this.heatInfo.air_permeability = _.get(
            detailFormData,
            "air_permeability",
            []
          );
        }
      },
      deep: true,
      immediate: true,
    },
    heatInfo: {
      handler(val) {
        let re_form = _.cloneDeep(val);
        this.$emit("update:addForm", re_form);
      },
      deep: true,
    },
  },
  mounted() {},
  methods: {
    addInfo(type) {
      if (type == "tensile") {
        let info = {
          tensile_stress: "",
          tensile_strain: "",
        };
        if (!this.heatInfo.tensile_properties) {
          this.heatInfo.tensile_properties = [];
        }
        this.heatInfo.tensile_properties.push(info);
      } else {
        let info = {
          pressure_range: "",
          flow_rate: "",
        };
        if (!this.heatInfo.air_permeability) {
          this.heatInfo.air_permeability = [];
        }
        this.heatInfo.air_permeability.push(info);
      }
    },
    deleteSteam(type, index) {
      if (type == "tensile") {
        this.heatInfo.tensile_properties =
          this.heatInfo.tensile_properties.filter((list, i) => {
            return index != i;
          });
      } else {
        this.heatInfo.air_permeability = this.heatInfo.air_permeability.filter(
          (list, i) => {
            return index != i;
          }
        );
      }
    },
  },
  components: {},
};
</script>

<style scoped lang="scss"></style>
