<template>
    <!-- 基本信息 -->
    <div class="baseinfo mb-5">
        <div class="row mb-4 justify-content-between">
            <div class="col-3">
                <div>
                    <label class="form-label">{{$t('attribute.membrane_thickness')}}</label>
                    <div class="input-group">
                        <input type="text" class="form-control" v-model="membraneInfo.thickness" />
                        <span class="input-group-text">cm</span>
                    </div>
                </div>
            </div>
            <div class="col-3">
                <div>
                    <label class="form-label">{{$t('attribute.membrane_type')}}</label>
                    <Multiselect class="form-control" v-model="membraneInfo.membrane_type" :close-on-select="true"
                        :searchable="true" :create-option="true" :options="type_options" />
                </div>
            </div>
            <div class="col-3">
                <div>
                    <label class="form-label">{{$t('attribute.membrane_location')}}</label>
                    <Multiselect class="form-control" v-model="membraneInfo.membrane_location" label="label"
                        value-prop="value" :close-on-select="true" :searchable="true" :create-option="true"
                        :options="address_options" />
                </div>
            </div>
        </div>
    </div>
    <div class="hand_feel">
        <div class="d-flex align-items-center justify-content-between">
            <label class="fs-18">{{$t('attribute.water_vapor_permeability')}}</label>
            <b-button variant="success" class="bg-gradient waves-effect waves-light text-end" @click="addInfo('water')">
                {{$t('base.add1')}}
            </b-button>
        </div>
        <div class="card-header" style="padding: 0.2rem 1rem"></div>
        <div style="max-height: 400px; overflow: auto">
            <table class="table">
                <thead class="table-light">
                    <tr>
                        <th scope="col">{{$t('attribute.number')}}</th>
                        <th scope="col">{{$t('attribute.temperature')}}</th>
                        <th scope="col">{{$t('attribute.water_vapor_permeability')}}</th>
                        <th>{{$t('base.operation')}}</th>
                    </tr>
                </thead>
                <tbody class="table-responsive">
                    <tr v-for="(water, index) in membraneInfo.water_vapour_permeability" :key="index">
                        <td>#{{ index + 1 }}</td>
                        <td>
                            <input type="text" class="form-control" v-model="water.temperature" />
                        </td>
                        <td>
                            <input type="text" class="form-control" v-model="water.wvp" />
                        </td>
                        <td>
                            <div @click="deleteSteam('water', index)">
                                <i class="ri-indeterminate-circle-line fs-16 text-secondary">
                                </i>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div class="skin_feel my-5">
        <div class="d-flex align-items-center justify-content-between">
            <label class="fs-18">{{$t('attribute.thermal_resistance')}}</label>
            <b-button variant="success" class="bg-gradient waves-effect waves-light text-end" @click="addInfo('hot')">
                {{$t('base.add1')}}
            </b-button>
        </div>
        <div class="card-header" style="padding: 0.2rem 1rem"></div>
        <div style="max-height: 400px; overflow: auto">
            <table class="table">
                <thead class="table-light">
                    <tr>
                        <th scope="col">{{$t('attribute.number')}}</th>
                        <th scope="col">{{$t('attribute.temperature')}}</th>
                        <th scope="col">{{$t('attribute.thermal_resistance')}}</th>
                        <th>{{$t('base.operation')}}</th>
                    </tr>
                </thead>
                <tbody class="table-responsive">
                    <tr v-for="(hot, index) in membraneInfo.thermal_resistance" :key="index">
                        <td>#{{ index + 1 }}</td>
                        <td>
                            <input type="text" class="form-control" v-model="hot.temperature" />
                        </td>
                        <td>
                            <input type="text" class="form-control" v-model="hot.tr" />
                        </td>
                        <td>
                            <div @click="deleteSteam('hot', index)">
                                <i class="ri-indeterminate-circle-line fs-16 text-secondary">
                                </i>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
    import _ from "lodash";
    import {
        type_options,
        membrane_location_options
    } from "@/views/fabric/datalist";

    export default {
        data() {
            return {
                membraneInfo: {
                    thickness: "",
                    membrane_type: "",
                    membrane_location: "",
                    water_vapour_permeability: [], // temperature wvp
                    thermal_resistance: [], // temperature tr
                },
                type_options,
                address_options: membrane_location_options,
              inited: false,
            };
        },
        props: {
            addForm: {
                type: Object,
                default: () => {},
            },
            detailForm: {
                type: Object,
                default: () => {},
            },
            searchList: {
                type: Object,
                default: () => {},
            },
        },
        emits: ["update:addForm"],
        watch: {
            detailForm: {
                handler(val) {
                    if (val && !this.inited) {
                      this.inited = true
                        const detailFormData = _.cloneDeep(val)
                        this.membraneInfo.thickness = _.get(detailFormData, 'thickness', '');
                        this.membraneInfo.membrane_type = _.get(detailFormData, 'membrane_type', '');
                        this.membraneInfo.membrane_location = _.get(detailFormData, 'membrane_location', '');

                        this.membraneInfo.water_vapour_permeability = _.get(detailFormData, 'water_vapour_permeability',
                            [])
                        this.membraneInfo.thermal_resistance = _.get(detailFormData, 'thermal_resistance', [])
                    }
                },
                deep: true,
                immediate: true,
            },
            searchList: {
                handler(val) {
                    if (val) {
                        // this.type_options = val.membrane_type;
                        // this.address_options = val.membrane_location;
                    }
                },
                deep: true,
                immediate: true,
            },
            membraneInfo: {
                handler(val) {
                    let re_form = _.cloneDeep(val);
                    this.$emit("update:addForm", re_form);
                },
                deep: true,
            },
        },
        mounted() {},
        methods: {
            addInfo(type) {
                if (type == "water") {
                    let info = {
                        temperature: "",
                        wvp: "",
                    };
                    this.membraneInfo.water_vapour_permeability.push(info);
                } else {
                    let info = {
                        temperature: "",
                        tr: "",
                    };
                    this.membraneInfo.thermal_resistance.push(info);
                }
            },
            deleteSteam(type, index) {
                if (type == "water") {
                    this.membraneInfo.water_vapour_permeability =
                        this.membraneInfo.water_vapour_permeability.filter((list, i) => {
                            return index != i;
                        });
                } else {
                    this.membraneInfo.thermal_resistance =
                        this.membraneInfo.thermal_resistance.filter((list, i) => {
                            return index != i;
                        });
                }
            },
        },
        components: {},
    };
</script>

<style scoped lang="scss">
    .hand_img,
    .skin_img {
        img {
            width: 20rem;
            height: 22rem;
        }
    }
</style>
