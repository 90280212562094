<template>
  <div
    :class="[
      'hand_feel',
      $route.query.chooseType &&
        $route.query.chooseType == 'hand' &&
        'choose_border',
    ]"
    style="padding-bottom: 2rem;"
  >
    <label class="fs-18">{{ $t("radar_chart.feel") }}</label>
    <div class="card-header" style="padding: 0.2rem 1rem"></div>
    <b-row>
      <b-col>
        <div class="hand_img">
          <img
            style="width: 100%; height: 400px; object-fit: contain"
            src="@/assets/img/hand_feel.png"
            alt=""
          />
        </div>
      </b-col>
      <b-col cols="4">
        <div
          class="hand_info d-flex flex-column justify-content-between"
          style="position: relative; top: 50%; transform: translateY(-50%)"
        >
          <div class="py-3">
            <label class="form-label" :class="[!isAdmin() ? 'sample-title' : '', !isAdmin() ? 'sample-title-first' : '']">{{ $t("radar_chart.smoothness") }}</label>
            <b-row>
              <b-col v-if="isAdmin()">
                <div class="d-flex">
                  <input
                    type="text"
                    class="form-control"
                    :value="hand_feel_star.roughness"
                    disabled
                  />
                  <div class="text-warning fs-20 ms-2">
                    <i
                      v-for="(cls, idx) in starUI(hand_feel_data.roughness)"
                      :key="idx"
                      :class="cls"
                    ></i>
                  </div>
                </div>
              </b-col>
              <b-col>
                <feel-tip-bar bar-type="hand_roughness" :value="hand_feel_data.roughness"></feel-tip-bar>
              </b-col>
            </b-row>
          </div>
          <div class="py-3">
            <label class="form-label" :class="[!isAdmin() ? 'sample-title' : '']">{{ $t("radar_chart.softness") }}</label>
            <b-row>
              <b-col v-if="isAdmin()">
                <div class="d-flex">
                  <input
                    type="text"
                    class="form-control"
                    :value="hand_feel_star.softness"
                    disabled
                  />
                  <div class="text-warning fs-20 ms-2">
                    <i
                      v-for="(cls, idx) in starUI(hand_feel_data.softness)"
                      :key="idx"
                      :class="cls"
                    ></i>
                  </div>
                </div>
              </b-col>
              <b-col>
                <feel-tip-bar bar-type="hand_softness" :value="hand_feel_data.softness"></feel-tip-bar>
              </b-col>
            </b-row>
          </div>
          <div class="py-3">
            <label class="form-label" :class="[!isAdmin() ? 'sample-title' : '']">{{ $t("radar_chart.warmth") }}</label>
            <b-row>
              <b-col v-if="isAdmin()">
                <div class="d-flex">
                  <input
                    type="text"
                    class="form-control"
                    :value="hand_feel_star.warmth"
                    disabled
                  />
                  <div class="text-warning fs-20 ms-2">
                    <i
                      v-for="(cls, idx) in starUI(hand_feel_data.warmth)"
                      :key="idx"
                      :class="cls"
                    ></i>
                  </div>
                </div>
              </b-col>
              <b-col>
                <feel-tip-bar bar-type="hand_warmth" :value="hand_feel_data.warmth"></feel-tip-bar>
              </b-col>
            </b-row>
          </div>
          <div class="py-3" v-if="isAdmin()">
            <label class="form-label">{{ $t("radar_chart.total") }}</label>
            <b-row>
              <b-col>
                <div class="d-flex">
                  <input
                    type="text"
                    class="form-control"
                    :value="hand_feel_star.total"
                    disabled
                  />
                  <div class="text-warning fs-20 ms-2">
                    <i
                      v-for="(cls, idx) in starUI(hand_feel_data.total)"
                      :key="idx"
                      :class="cls"
                    ></i>
                  </div>
                </div>
              </b-col>
              <b-col></b-col>
            </b-row>
          </div>
        </div>
      </b-col>
      <b-col>
        <div class="hand_chart ms-5">
          <div :id="handChartId" style="height: 400px; width: 470px"></div>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { radarChart } from "@/utils/chart.js";
import _ from "lodash";
import { starUI } from "@/utils/common";
import FeelTipBar from "@/components/feel-tip-bar.vue";
import { isAdmin } from "@/utils/permission.js";
export default {
  props: {
    detailForm: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      handChartId: `hand${+new Date()}`,
      hand_feel_star: {
        roughness: "",
        softness: "",
        warmth: "",
        total: "",
      },
      hand_feel_data: {
        roughness: "",
        softness: "",
        warmth: "",
        total: "",
      },
      hand_feel_score: {
        roughness: "",
        softness: "",
        warmth: "",
        total: "",
      },
      name: "",
    };
  },
  mounted() {
    // this.$nextTick(() => {
    //     this.initChart();
    // });
  },
  methods: {
    starUI,
    isAdmin,
    initChart() {
      this.hand_feel_chart();
    },
    hand_feel_chart() {
      let options = {
        data: [
          this.get_number_default(this.hand_feel_data.softness),
          this.get_number_default(this.hand_feel_data.roughness),
          this.get_number_default(this.hand_feel_data.warmth),
          this.get_number_default(this.hand_feel_data.total),
        ],
        ave: [
          this.get_number_default(this.hand_feel_score.softness),
          this.get_number_default(this.hand_feel_score.roughness),
          this.get_number_default(this.hand_feel_score.warmth),
          this.get_number_default(this.hand_feel_score.total),
        ],
        color: "#50C3E6",
        name: this.name,
        chartDom: document.querySelector(`#${this.handChartId}`),
      };
      radarChart(options, 'pc', 'hand');
    },
    number_fixed(data) {
      if (data == "") {
        return data;
      }
      return parseFloat(data.toFixed(2));
    },
    get_number_default(data) {
      if (data == "") {
        return 0;
      }
      return data;
    },
    star_classes(star) {
      const arr = [];
      for (let i = 0; i < 5; i++) {
        if (star <= 0) {
          arr[i] = "ri-star-line";
          continue;
        }
        let current = i + 1;
        if (current <= star) {
          arr[i] = "ri-star-fill";
        } else if (current - 0.5 <= star) {
          arr[i] = "ri-star-half-fill";
          break;
        } else {
          arr[i] = "ri-star-line";
        }
      }
      if (arr.length < 5) {
        for (let i = arr.length; i < 5; i++) {
          arr[i] = "ri-star-line";
        }
      }
      return arr;
    },
  },
  watch: {
    detailForm: {
      handler(val) {
        if (val) {
          this.name = _.get(val, "name", "");
          this.hand_feel_star.roughness = this.number_fixed(
            _.get(val, "hand_feel_garment_roughness", "")
          );
          this.hand_feel_star.softness = this.number_fixed(
            _.get(val, "hand_feel_garment_softness", "")
          );
          this.hand_feel_star.warmth = this.number_fixed(
            _.get(val, "hand_feel_garment_warmth", "")
          );
          this.hand_feel_star.total = this.number_fixed(
            _.get(val, "total_hand_feel", "")
          );

          this.hand_feel_data.roughness = this.number_fixed(
            _.get(val, "score.hfgr_normalized", 0)
          );
          this.hand_feel_data.softness = this.number_fixed(
            _.get(val, "score.hfgs_normalized", 0)
          );
          this.hand_feel_data.warmth = this.number_fixed(
            _.get(val, "score.hfgw_normalized", 0)
          );
          this.hand_feel_data.total = this.number_fixed(
            _.get(val, "score.thf_normalized", 0)
          );

          this.hand_feel_score.roughness = this.number_fixed(
            _.get(val, "avg_score.hfgr_normalized", 0)
          );
          this.hand_feel_score.softness = this.number_fixed(
            _.get(val, "avg_score.hfgs_normalized", 0)
          );
          this.hand_feel_score.warmth = this.number_fixed(
            _.get(val, "avg_score.hfgw_normalized", 0)
          );
          this.hand_feel_score.total = this.number_fixed(
            _.get(val, "avg_score.thf_normalized", 0)
          );

          this.$nextTick(() => {
            this.initChart();
          });
        }
      },
      deep: true,
      immediate: true,
    },
  },
  components: {
    FeelTipBar,
  },
};
</script>

<style scoped lang="scss">
.hand_img,
.skin_img {
  img {
    width: 20rem;
    height: 22rem;
  }
}
.sample-title {
  margin-bottom: 2.5rem;
  margin-left: -1.2rem;
  margin-top: 2rem;
}
.sample-title-first {
  margin-top: 0;
}
</style>
