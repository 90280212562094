<template>
  <div>
    <Add v-if="showType != 'view'"></Add>
    <View v-else :detailForm="detailForm"></View>
  </div>
</template>

<script>
import Add from "./skin/Add.vue";
import View from "./skin/View.vue";
export default {
  data() {
    return {};
  },
  props:{
    showType:{
      type:String,
      default:''
    },
    detailForm: {
      type: Object,
      default: () => {},
    },
  },
  mounted() {},
  methods: {},
  components: {
    Add,
    View,
  },
};
</script>

<style scoped lang="scss"></style>
